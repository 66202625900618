<template>
  <base-layout page-title="Add a post" page-default-back-link="/posts" showBackButton="true">
    <create-post-form @save-post="savePost"></create-post-form>
  </base-layout>
</template>

<script>
import CreatePostForm from "../components/posts/CreatePostForm.vue";

export default {
  components: {
    CreatePostForm,
  },
  methods: {
    savePost(postData) {
      this.$store.dispatch('addpost', postData);
      this.$router.replace('/posts');
    }
  }
};
</script>